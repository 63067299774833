<template>
  <div class="i-wrap">
    <div class="i-content">
      <div class="item" v-for="(item, index) in stages" :key="index">
        <div class="item-main">
          <div class="info">
            <div class="info-img">
              <img class="img-main" v-if="item.approvalStatus == 'UNREVIEWED'" src="@/assets/icon/declare/submit.png">
              <img class="img-main" v-if="item.approvalStatus == 'APPROVALPENDING' || item.approvalStatus == 'APPROVED'"
                   src="@/assets/icon/declare/approval.png">
              <img class="img-main"
                   v-if="item.approvalStatus == 'REVIEWFAILED' ||item.approvalStatus == 'APPROVALFAILED'"
                   src="@/assets/icon/declare/faild.png">
              <img class="bottom-after"
                   v-if="item.approvalStatus == 'REVIEWFAILED' ||item.approvalStatus == 'APPROVALFAILED'"
                   src="@/assets/icon/declare/reject.png">
              <img class="bottom-after"
                   v-if="item.approvalStatus == 'APPROVALPENDING' ||item.approvalStatus == 'APPROVED'"
                   src="@/assets/icon/declare/success.png">
            </div>
            <div class="info-message">
              <div class="approval-user">{{ item.operator }}</div>
              <div class="approval-time">{{ item.created }}</div>
            </div>
          </div>
          <div class="stage-status" :class="item.approvalStatus">
            <span>{{ fmtStatus(item.approvalStatus) }}</span>
            <span v-if="item[`${item.approvalStatus}-size`] > 1">(第{{ item[`${item.approvalStatus}-size`] }}次)</span>
          </div>
        </div>
        <div v-if="index < stages.length - 1" class="item-line"></div>
      </div>
    </div>
    <div class="i-reason">
      <div class="reason-item" v-if="r.reason" v-for="(r, i) in stages" :key="i">
        <div class="header">
          <div class="title">
            <span>{{ r.operator }}</span>
            <span v-if="r[`${r.approvalStatus}-size`] > 1">第{{ r[`${r.approvalStatus}-size`] }}次</span>
            <span class="stage-status" :class="r.approvalStatus">{{ fmtStatus(r.approvalStatus) }}</span>
            <span>{{ fmtOperatorStatus(r.approvalStatus) }}</span>
          </div>
          <div class="time">{{ r.created }}</div>
        </div>
        <div class="content">{{ r.reason }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDeclareByDraftTableId,
  approvalLogList
} from '@/service/declare/index'

export default {
  name: 'Approval',
  filters: {
    fmtApprovalBack (val) {
      return val
    }
  },
  data () {
    return {
      id: btoa(this.$route.query.id),
      // 流程列表
      stages: [],
      approvalStatusList: []
    }
  },
  created () {
    this.getDicts('dec_approval_status').then(res => {
      this.approvalStatusList = res.data
      this.handleInitData()
    })
  },
  methods: {
    async handleInitData () {
      const { success, data } = await getDeclareByDraftTableId(this.id)
      approvalLogList({ declareId: data.id }).then(res => {
        let statusComputed = {}
        res.data.forEach(i => {
          if (!statusComputed[i.approvalStatus] && statusComputed[i.approvalStatus] != 0) statusComputed[i.approvalStatus] = 1
          else statusComputed[i.approvalStatus]++
          i[`${i.approvalStatus}-size`] = statusComputed[i.approvalStatus]
        })
        this.stages = res.data
      })
    },
    fmtStatus (val) {
      let label = this.getDictLabel(this.approvalStatusList, val)
      switch (val) {
        case 'UNREVIEWED' :
          return '发起申请'
        case 'APPROVALPENDING' :
          return '复核通过'
        default:
          return label
      }
    },
    fmtOperatorStatus (val) {
      let label = this.getDictLabel(this.approvalStatusList, val)
      switch (val) {
        case 'UNREVIEWED' :
          return '的备注:'
        case 'APPROVALPENDING' :
          return '的审批意见:'
        default:
          return label
      }
    }
  }
}
</script>

<style scoped lang="scss">
.i-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
  background-color: #fff;
  min-height: calc(100% - 110px);
  box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
}

.i-content {
  width: 380px;
  min-width: 380px;
  padding: 44px 40px;
  border: 1px solid #E5E5E5;
}

.item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  .item-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .info-img {
        position: relative;

        .img-main {
          width: 30px;
          height: 30px;
        }

        .bottom-after {
          width: 14px;
          height: 14px;
          margin-top: 18px;
          margin-left: -8px;
          position: absolute;
        }
      }

      .info-message {
        color: #666666;
        margin-left: 16px;

        .approval-time {
          font-size: 11px;
        }
      }
    }
  }

  .item-line {
    height: 30px;
    margin: 4px 15px 6px;
    border: 0.5px solid #3D94FF;
  }

}

.stage-status {
  color: #09BB07;
}

.REVIEWFAILED, .APPROVALFAILED {
  color: #FF2600;
}

.i-reason {
  flex: auto;
  margin-left: 40px;
  padding: 20px 40px;
  border: 1px solid #E5E5E5;

  .reason-item {
    padding: 24px;
    border-bottom: 1px solid #E5E5E5;

    span {
      padding: 0 2px;
    }

    .header {
      display: flex;
      font-size: 16px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .time {
        font-size: 12px;
        color: #999999;
      }
    }

    .content {
      width: 100%;
      word-break: break-word;
      padding-top: 12px;
      color: #666666;
    }
  }
}
</style>
